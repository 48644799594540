<template>
  <div class="html-container" v-html="message"></div>
</template>

<script>
// eslint-disable-next-line import/no-cycle
import httpApi from '../utils/httpApi';

export default {
  name: 'Contract',
  data() {
    return {
      message: '',
    };
  },
  methods: {
    before() {
      this.$router.back();
    },
  },
  async mounted() {
    this.$store.commit('setTitleValue', '查看');
    this.$store.commit('setStepsShow', false);
    try {
      const resultMsg = await httpApi.getStockAgreementInfo();
      if (resultMsg.code === 200) {
        this.message = resultMsg.data.agreement;
      } else {
        this.$toast(resultMsg.msg || '查询失败');
      }
    } catch (error) {
      this.$toast('查询失败');
    }
  },
};
</script>

<style lang="less">
</style>
